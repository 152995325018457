/**
 * An array of routes that are used in the application tp public
 * These routes do not require authentication
 * @type {string[]}
 */
export const publicRoutes = [
  "/",
  "/new-verification",
  "/questions",
  "/questions/*",
  "/ads.txt",
  "/course",
  "/course/*",
  "/subjects/*",
  "/subsubjects/*",
  "/topics/*",
  "/sitemap.xml",
  "/categories",
  "/categories/*",
  "/contact-us",
  "/terms-and-conditions",
  "/privacy-policy",
  
];

/**
 * An array of routes that are used to redirect to /settings
 * These routes  require authentication
 * @type {string[]}
 */
export const authRoutes = [
  "/sign-in",
  "/sign-up",
  "/error",
  "/forgot-password",
  "/new-password",
  
];

/**    The prefix for API authentication routes
 * Routes that start with this prefix are used for API authentication purposes
 * @type {string}
 */
export const apiAuthPrefix = "/api/auth";

/**
 * The default route to redirect to after login
 * @type {string}
 */
export const DEFAULT_LOGIN_REDIRECT = "/";
