

import * as z from "zod";

export const ResetSchema = z.object({
  email: z.string().email(),
 
}); 
export const NewPasswordSchema = z.object({
  password: z.string().min(8),
});



  export const LoginSchema = z.object({
    email: z.string().email(),
    password: z.string().min(8),
    code: z.string().optional(),
    ipAddress: z.string().optional(),
    deviceInfo: z.string().optional(),
  }); 

  export const getSignUpSchema = (type: string) =>
    z.object({
      email: z.string().email(),
      password: z.string().min(8),
      name: type === "sign-in" ? z.string().optional() : z.string().min(3),
      address1: type === "sign-in" ? z.string().optional() : z.string().max(50),
      state:
        type === "sign-in" ? z.string().optional() : z.string().min(2).max(50),
      postalCode:
        type === "sign-in" ? z.string().optional() : z.string().min(3).max(6),
      dateOfBirth:
        type === "sign-in" ? z.string().optional() : z.string().min(3),
      ipAddress:  z.string().min(1) ,
      deviceInfo:  z.string().min(1) ,
    });

    