"use client"

import { signIn } from "next-auth/react"
import { FcGoogle } from "react-icons/fc"
import { Button } from "../ui/button"
import { DEFAULT_LOGIN_REDIRECT } from "../../../routes"

export const SocialLogin = () => {
    const onClick=(provider:"google")=>{
        signIn(provider, {
          callbackUrl: DEFAULT_LOGIN_REDIRECT,
      })
    }
  return (
    <div>
      <Button
        onClick={() => onClick("google")}
        variant="outline"
        className="w-full mt-2"
      >
        <FcGoogle className="h-4 w-4 mr-2" />
        Login with Google
      </Button>
    </div>
  );
}