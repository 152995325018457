"use client";

import Link from "next/link";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {LoginSchema } from "@/../schemas";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { use, useState, useTransition, useId, useEffect } from "react";
import { Loader2 } from "lucide-react";
import { logIn } from "@/lib/actions/user.actions";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { CheckCircle, XCircle } from "lucide-react";
import { SocialLogin } from "./social";
import { useSearchParams } from "next/navigation";
import { UAParser } from "ua-parser-js";
import axios from "axios";



export const LoginForm = () => {
  
  const searchParams = useSearchParams();
  const urlError =
    searchParams.get("error") === "OAuthAccountNotLinked"
      ? "Email already in use. Please sign in with your email and password."
      : "";
  const [showTwoFactor, setShowTwoFactor] = useState(false);
  const [isPending, startTransition] = useTransition();
  const [error, setError] = useState<string | undefined>("");
  const [success, setSuccess] = useState<string | undefined>("");
  const [ipAddressData, setIpAddressData] = useState<any | undefined>(null);
  const [deviceInfo, setDeviceInfo] = useState<string | undefined>("");
  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://freeipapi.com/api/json/");
        const {
          ipAddress,
          countryName,
          cityName,
          regionName,
          isProxy,
          currency,
        } = response.data;
        setIpAddressData({
          ipAddress,
          countryName,
          cityName,
          regionName,
          isProxy,
          currency,
        });
      } catch (error) {
        console.error("Failed to fetch IP address:", error);
      }
    };

    const parser = new UAParser();
    const result = parser.getResult();
    setDeviceInfo(JSON.stringify(result));

    fetchIpAddress();
  }, []);
    
  const form = useForm<z.infer<typeof LoginSchema>>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      email: "",
      password: "",
      code: "",
      ipAddress: "",
      deviceInfo:"",
    },
  });

  useEffect(() => {
    if (ipAddressData && deviceInfo) {
      form.setValue("ipAddress", JSON.stringify(ipAddressData));
      form.setValue("deviceInfo", deviceInfo);
    }
  }, [ipAddressData, deviceInfo, form]);

 
  const onSubmit = async (values: z.infer<typeof LoginSchema>) => {
    setError("");
    setSuccess("");
    startTransition(() => {
      logIn(values)
        .then((data) => {
          if (data?.error) {
            form.reset();
            setError(data.error);
          }
          if (data?.success) {
            form.reset();
            setSuccess(data.success);
          }
          if (data?.twoFactor) {
            setShowTwoFactor(true);
          }
        })
        .catch(() => {
          setError("Failed to log in");
        });
    });
    };

  const emailId = useId();
  const passwordId = useId();




  return (
    <Card className="mx-auto w-full max-w-4xl">
      <CardHeader>
        <CardTitle className="text-xl">Sign in</CardTitle>
        <CardDescription>Enter your credentials to sign in</CardDescription>
      </CardHeader>
      <CardContent>
        <>
          <div className="grid gap-4">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                {showTwoFactor && (
                  <FormField
                    control={form.control}
                    name="code"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel htmlFor="code">Two Factor Code</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            disabled={isPending}
                            id="code"
                            placeholder="123456"
                            required
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
                {!showTwoFactor && (
                  <div className="grid gap-2">
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel htmlFor={emailId}>Email</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              disabled={isPending}
                              id={emailId}
                              type="email"
                              placeholder="m@example.com"
                              required
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="password"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel htmlFor={passwordId}>Password</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              disabled={isPending}
                              id={passwordId}
                              type="password"
                              placeholder="Password"
                              required
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                )}
                <Button
                  size="sm"
                  variant="link"
                  asChild
                  className="text-purple-600 px=0 font-normal underline hover:underline-offset-8"
                >
                  <Link href={"/forgot-password"}>Forgot Password?</Link>
                </Button>

                {(error || urlError) && (
                  <Alert variant="destructive" className="mt-2">
                    <XCircle className="h-4 w-4" />
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>{error || urlError}</AlertDescription>
                  </Alert>
                )}

                {success && (
                  <Alert variant="default" className="mt-2 bg-emerald-500/30">
                    <CheckCircle className="h-4 w-4" />
                    <AlertTitle>Success</AlertTitle>
                    <AlertDescription>{success}</AlertDescription>
                  </Alert>
                )}

                <Button
                  type="submit"
                  disabled={isPending}
                  className="form-btn w-full mt-4"
                >
                  {isPending ? (
                    <>
                      <Loader2 size={20} className="animate-spin" /> &nbsp;
                      Loading...
                    </>
                  ) : showTwoFactor ? (
                    "Confirm"
                  ) : (
                    "Sign In"
                  )}
                </Button>
              </form>
            </Form>

            <SocialLogin />
          </div>

          <div className="flex justify-center gap-4 mt-4">
            <p className="text-14 font-normal text-white-600">
              Don&apos;t have an account?
            </p>
            <Link
              href={"/sign-up"}
              className="form-link  text-purple-600 underline hover:underline-offset-8"
            >
              Sign Up
            </Link>
          </div>
        </>
      </CardContent>
    </Card>
  );
  

}


